import { FormGroup } from '@angular/forms';

import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { Injectable } from '@angular/core';

import {
  AuditPlanAuditableItemDetailsDTO,
  AuditPlanAuditableItemFormDTO,
  AuditPlanAuditableItemListDTO,
  TransformedAuditPlanAuditableItemListDTO,
} from '../dto/audit-plan-auditable-item.dto';
import { convertResponseToAuditorRiskRatingDetailsDTO } from '../../../settings/audit/dto/auditor-risk-rating.dto';
import { MasterConversion } from '../../../../shared/conversion/master.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { AuditUniverseAuditableItemConversion } from '../../audit-universe/conversion/audit-universe-auditable-item.conversion';

@Injectable({
  providedIn: 'root',
})
// implements MainConversionInterface<AuditPlanAuditableItemListDTO, AuditPlanAuditableItemFormDTO, AuditPlanAuditableItemDetailsDTO>
export class AuditPlanDetailsAuditableItemConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private auditUniverseAuditableItemConversion: AuditUniverseAuditableItemConversion,
    private tableConv: TableConversion,
    private masterConv: MasterConversion,
    private userConversion: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }
  // resToList(response: AuditPlanAuditableItemListDTO[]): AuditPlanAuditableItemListDTO[] {
  //   if (!response) {
  //     return [];
  //   }
  //   const result = response.map((item: any) => {
  //     const { title, type } = getAuditableItemTypeTitle(item, this.lang);
  //     return {
  //       id: item.id,
  //       reference_number: item.reference_number,
  //       audit_plan_id: item.audit_plan_id,
  //       comment: item.auditable_item_comment,
  //       audit_plan_title: item.audit_plan_title,
  //       audit_status: this.tableConv.resToColordItem(item, 'audit_status'),
  //       audit_team: item['audit_team_title_' + this.lang],
  //       responsible_unit: item['responsible_unit_title_' + this.lang],
  //       auditable_item_id: item.auditable_item_id,
  //       auditable_item_type: type,
  //       auditor_risk_rating: this.tableConv.resToColordItem(item, 'auditor_risk_rating'),
  //       compliance_requirement_id: item.compliance_requirement_id,
  //       control_id: item.control_id,
  //       document_id: item.document_id,
  //       incident_register_id: item.incident_register_id,
  //       process_id: item.process_id,
  //       risk_register_id: item.risk_register_id,
  //       user_id: item.user_id,
  //       auidtable_item_title : title
  //     };
  //   });
  //   return result;
  // }

  resToList(
    response: AuditPlanAuditableItemListDTO[]
  ): TransformedAuditPlanAuditableItemListDTO[] {
    if (!response) {
      return [];
    }

    // Group the response by type
    const groupedData = response.reduce((acc, item) => {

      const { title, type } =
        this.auditUniverseAuditableItemConversion.getAuditableItemTypeTitle(
          item
        );

      if (!acc[type]) {
        acc[type] = [];
      }
      const responsibleUnit = item[
        ('responsible_unit_title_' +
          this.lang) as keyof AuditPlanAuditableItemListDTO
      ] as string;
      const responsibleUser = this.userConversion.resToUserMinimalDTO(
        item,
        'responsible_user'
      ); //@todo
      acc[type].push({
        id: item.id,
        audit_plan_id: item.audit_plan_id,
        auditable_item_id: item.auditable_item_id,
        auditable_item_comment: item.auditable_item_comment,
        audit_status: this.tableConv.resToColordItem(item, 'audit_status'),
        responsible_unit: responsibleUnit,
        responsible_user: responsibleUser,
        auditable_item_type: type,
        auditor_risk_rating: this.tableConv.resToColordItem(
          item,
          'auditor_risk_rating'
        ),
        auidtable_item_title: title,
      });

      return acc;
    }, {} as { [key: string]: TransformedAuditPlanAuditableItemListDTO[] });

    const result: TransformedAuditPlanAuditableItemListDTO[] = [];

    // Iterate through grouped data and apply rowspan
    for (const type in groupedData) {
      const items = groupedData[type];
      const rowspan = items.length;

      items.forEach((item, index) => {
        if (index === 0) {
          result.push({ ...item, rowspan });
        } else {
          const { auditable_item_type, ...rest } = item;
          result.push({ ...rest });
        }
      });
    }
    return result;
  }

  formGroupToForm(formGroup: FormGroup): AuditPlanAuditableItemFormDTO {
    return {
      id: formGroup.get('id')?.value,
      auditable_item_ids: formGroup.get('AuditableItemID')?.value || [],
    };
  }
  resToForm(response: any): any {
    return {
      id: response.id,
      AuditableItemID: response.auditable_item_ids,
    };
  }

  resToDetails(response: any): AuditPlanAuditableItemDetailsDTO {
    const { title, type } = getAuditableItemTypeTitleDetails(
      response.auditable_item,
      this.lang
    );
    return {
      id: response.id,
      reference_number: response.reference_number,
      audit_plan_id: response.audit_plan_id,
      comment: response.comment,
      audit_plan: response.audit_plan,
      audit_status: this.masterConv.resToDetails(
        response.auditable_item.audit_status
      ),
      audit_team: response['audit_team_title_' + this.lang],
      responsible_unit:
        response.auditable_item.responsible_unit['title_' + this.lang],
      auditable_item_id: response.auditable_item_id,
      auditable_item_type: type,
      auditor_risk_rating: convertResponseToAuditorRiskRatingDetailsDTO(
        response.auditable_item.auditor_risk_rating,
        this.lang
      ),
      compliance_requirement_id: response.compliance_requirement_id,
      control_id: response.control_id,
      document_id: response.document_id,
      incident_register_id: response.incident_register_id,
      process_id: response.process_id,
      risk_register_id: response.risk_register_id,
      user_id: response.user_id,
      auidtable_item_title: title,
    };
  }
}

export function getAuditableItemTypeTitleDetails(
  item: any,
  lang: string
): { title: string; type: string } {
  let auditableItemTitle = '';
  let auditableItemType = '';
  switch (item.type) {
    case 'control':
      auditableItemTitle = item.control['title_' + lang];
      auditableItemType = 'Control';
      break;
    case 'process':
      auditableItemTitle = item.process['title_' + lang];
      auditableItemType = 'Process';
      break;
    case 'risk':
      auditableItemTitle = item.risk_register.title;
      auditableItemType = 'Risk';
      break;
    case 'compliance_requirement':
      auditableItemTitle = item.compliance_requirement.title;
      auditableItemType = 'Compliance Requirement';
      break;
    case 'compliance_register':
      auditableItemTitle = item.compliance_register.title;
      auditableItemType = 'Compliance Register';
      break;
    case 'incident':
      auditableItemTitle = item.incident_register.title;
      auditableItemType = 'Incident Register';
      break;
    case 'user':
      auditableItemTitle =
        item.user['first_name_' + lang] + ' ' + item.user['last_name_' + lang];
      auditableItemType = 'User';
      break;
    case 'document':
      auditableItemTitle = item.document['document_title_' + lang];
      auditableItemType = 'Policy and Procedure';
      break;
    default:
      auditableItemTitle = item['title_' + lang];
      auditableItemType = 'Custom';
      break;
  }

  return { title: auditableItemTitle, type: auditableItemType };
}
